import axios from 'axios'
import slug from 'slug'

const mapProducts = type => product => {
  const path = type === 'exams' ? '/exames/' : '/vacinas/'
  return {
    id: product.id,
    nome: product.nome,
    sinonimo: product.sinonimias,
    slug: `${path}${slug(product.nome)}`,
  }
}

const mapUnits = unit => {
  return {
    nome: unit.nome,
    id: unit.id,
    estado: unit.uf,
    slug: `/unidades/${slug(unit.nome)}`,
  }
}

const filterUnits = unit =>
  !/desativa/i.test(unit.nome) &&
  !/UPA/i.test(unit.nome) &&
  !/SUS/i.test(unit.nome) &&
  !/Clínica/i.test(unit.nome) &&
  !/Sem previsão/i.test(unit.nome) &&
  unit.cidade !== null

const sortUnits = (a, b) => {
  if (a.nome > b.nome) {
    return 1
  }
  if (a.nome < b.nome) {
    return -1
  }
  return 0
}

const ProductService = {
  search: async (query, type) => {
    const typeId = type === 'exams' ? 1 : 2
    try {
      const url = `${process.env.GATSBY_DP_SEARCH_URL}?idMarca=${process.env.GATSBY_BRAND_ID}&q=${query}&idTipo=${typeId}`
      const result = await axios.get(url)
      return result.status === 204 ? [] : result.data.map(mapProducts(type))
    } catch (error) {
      return []
    }
  },

  getUnitsPerProduct: async idProduct => {
    try {
      const multiDP = process.env.GATSBY_BRAND_ID.split(',')

      const resultado = await Promise.all(
        multiDP.map(async idDPMarca => {
          const url = `${process.env.GATSBY_DP_UNITS_URL}?idMarca=${idDPMarca}&idProduto=${idProduct}`
          const retorno = await axios.get(url)
          return retorno.status === 204
            ? []
            : retorno.data[0].unidades.filter(filterUnits).map(mapUnits).sort(sortUnits)
        })
      )
      const resultadoFinal = await resultado.reduce((list, sub) => list.concat(sub), [])

      return resultadoFinal
    } catch (error) {
      return []
    }
  },
}

export default ProductService
