import React, { useEffect } from 'react'

import * as S from './styled'
import Title from './Title'
import AnsCover from './AnsCover'
// import Deadline from './Deadline'
import Prepare from './Prepare'

import { useProductDetailContext } from '../../../contexts'

const DetailList = () => {
  const { productDetail: product } = useProductDetailContext()

  useEffect(() => {
    window.addEventListener('onpopstate', () => {
      localStorage.setItem('detail-back-event', 'true')
    })

    return () => {
      window.removeEventListener('onpopstate', () => {})
    }
  }, [])

  return (
    <S.DetailListContainer>
      <Title />
      {product.type === 'exame' || product.type === 'exam' ? (
        <>
          <AnsCover />
        </>
      ) : null}
      <Prepare />
    </S.DetailListContainer>
  )
}

export default DetailList
