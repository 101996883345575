import React from 'react'

import * as S from './styled'

import { useProductContext } from '../../../contexts'

const Title = () => {
  const {
    productsData: { title, demonstrative_text: html },
  } = useProductContext()

  return (
    <>
      <S.Title>{title}</S.Title>
      <S.Description dangerouslySetInnerHTML={{ __html: html }} />
    </>
  )
}

export default Title
