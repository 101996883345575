import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as S from './styled'

import DetailList from './DetailList'
import Unit from './Unit'
import Price from './Price'

import { useProductDetailContext } from '../../contexts'

const ProductDetail = ({ setPageType }) => {
  const { productDetail: product, examSettings, vaccineSettings } = useProductDetailContext()

  useEffect(() => {
    setPageType(product.type)
  }, [product])

  const productSettings = product.type === 'exame' ? examSettings.acf : vaccineSettings.acf

  const isAltaTheme = Number(process.env.GATSBY_BRAND_ID) === 19
  return (
    <S.ProductDetailContainer altaTheme={isAltaTheme}>
      <DetailList />
      {productSettings.product_budget ? (
        <Price productSettings={productSettings} />
      ) : (
        <Unit productSettings={productSettings} />
      )}
    </S.ProductDetailContainer>
  )
}

ProductDetail.propTypes = {
  setPageType: PropTypes.func,
}

const mapStateToProps = ({}) => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setPageType: pageType => dispatch({ type: 'SET_PAGE_TYPE', payload: pageType }),
  }
}

const ConnectedProductDetail = connect(mapStateToProps, mapDispatchToProps)(ProductDetail)

export default ConnectedProductDetail
