import styled from 'styled-components'
import media from 'styled-media-query'

import { AccordionItemButton } from 'react-accessible-accordion'

export const PrepareContainer = styled.div`
  ${media.lessThan('medium')`
    box-sizing: border-box;
    margin-bottom:4rem;
  `}
`

export const PrepareItemContainer = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 5fr;
  row-gap: 0.8rem;
  margin-bottom: 3.8rem;

  ${media.lessThan('medium')`
    grid-template-columns: 0.5fr 5fr;
    row-gap: 1.6rem;
    margin-bottom: 2.4rem;
  `}
`

export const PrepareTypeIcon = styled.svg`
  grid-column: 1;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.6rem;
`

export const PrepareTypeTitle = styled.h2`
  grid-column: 2;
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: normal;
  margin: 0;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};

  ${media.lessThan('medium')`
    font-size: 2rem;
    line-height: 2.4rem;
  `}
`

export const PrepareTypeInfo = styled.p`
  grid-column: 2;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  margin: 0;
`

// ACCORDION

export const AccordionItemButtonCustom = styled(AccordionItemButton)`
  grid-column: 2;
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: normal;
  margin: 0 0 3.8rem 0;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  outline: none;
  cursor: pointer;

  ${media.lessThan('medium')`
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 0 2.4rem 0;
  `}

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    position: relative;
    top: 8px;
    right: 4px;
    float: right;
  }

  &[aria-expanded='true'],
  &[aria-selected='true'] {
    &::after {
      transform: rotate(-135deg);
    }
  }
`
