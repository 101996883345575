import styled from 'styled-components'
import media from 'styled-media-query'
import { TranslateLink } from 'gatsby-plugin-translate'

export const TopProductsContainer = styled.div`
  /* height: max-content; */
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};
  padding: 3.2rem;
  grid-area: topProducts;

  ${media.lessThan('medium')`
    padding: 2.4rem;
    margin-bottom: 10.4rem;
  `}
`

export const TopProductsTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};
  margin: 0 0 1.2rem 0;
`

export const TopProductsItemContainer = styled(TranslateLink)`
  width: fit-content;
  font-size: 1.4rem;
  color: ${props => props.theme.colors.beigeGray};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`
export const TopProductsItem = styled.h3`
  font-weight: normal;
`
