import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BlobProvider } from '@react-pdf/renderer'
import SyncLoader from 'react-spinners/SyncLoader'

import * as S from './styled'

import BudgetDocument from '../BudgetPrint/BudgetDocument'
import budgetEmailService from './budgetEmail.service'

const BudgetEmail = ({ pageType, cartItemsExam, cartItemsVaccine, onCancel, logo }) => {
  const [sendingMail, setSendingMail] = useState(false)
  const [emailSucessMessage, setEmailSucessMessage] = useState(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState(false)
  const showMailForm = !sendingMail && !emailSucessMessage && !emailErrorMessage
  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  const PdfBlobContainer = (
    <>
      <BudgetDocument cartItems={cartItems} logo={logo} />
    </>
  )

  const sendEmail = blob => email => {
    setSendingMail(true)
    budgetEmailService.send(blob, email).then(response => {
      setSendingMail(false)
      if (response.status === 200) {
        setEmailSucessMessage(true)
      } else {
        setEmailErrorMessage(true)
      }
    })
  }

  const MailForm = ({ click, cancel }) => {
    const [email, setEmail] = useState('')

    const isEmailInvalid = emailAddress => {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const validEmail = re.test(String(emailAddress).toLowerCase())
      return !validEmail
    }

    return (
      <>
        <S.SendEmalTitle>Receber o seu orçamento por e-mail:</S.SendEmalTitle>
        <S.SendEmailInputContainer>
          <S.EmailIconInput />
          <S.SendEmailInput
            type="mail"
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder="Digite seu e-mail"
          />
        </S.SendEmailInputContainer>
        <S.SendEmailActionContainer>
          <S.SendEmailButton onClick={cancel}>Cancelar</S.SendEmailButton>
          <S.SendEmailButton onClick={() => click(email)} disabled={isEmailInvalid(email)}>
            Enviar
          </S.SendEmailButton>
        </S.SendEmailActionContainer>
      </>
    )
  }

  MailForm.propTypes = {
    click: PropTypes.func,
    cancel: PropTypes.func,
  }

  const MailSuccess = () => (
    <>
      <S.EmailSucessMessage>Orçamento enviado com sucesso!</S.EmailSucessMessage>
      <S.BudgetBackButton onClick={onCancel}>Voltar</S.BudgetBackButton>
    </>
  )

  const MailError = () => (
    <>
      <S.EmailErrorMessage>Não foi possível enviar seu orçamento</S.EmailErrorMessage>
      <S.BudgetBackButton onClick={onCancel}>Voltar</S.BudgetBackButton>
    </>
  )

  const MailSending = () => (
    <S.SendingMailContainer>
      <SyncLoader loading={true} color="#aaa" margin="5px" size="10px" />
    </S.SendingMailContainer>
  )

  return (
    <BlobProvider document={PdfBlobContainer}>
      {({ blob }) => {
        return (
          <S.EmailContainer>
            {sendingMail && <MailSending />}
            {emailErrorMessage && <MailError />}
            {emailSucessMessage && <MailSuccess />}
            {showMailForm && <MailForm blob={blob} click={sendEmail(blob)} cancel={onCancel} />}
          </S.EmailContainer>
        )
      }}
    </BlobProvider>
  )
}

BudgetEmail.propTypes = {
  pageType: PropTypes.string,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  onCancel: PropTypes.func,
  logo: PropTypes.string,
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine }) => {
  return { pageType, cartItemsExam, cartItemsVaccine }
}

const ConnectedBudgetEmail = connect(mapStateToProps)(BudgetEmail)

export default ConnectedBudgetEmail
