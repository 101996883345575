import React, { memo } from 'react'
import * as S from './styled'

import { useTranslations } from 'gatsby-plugin-translate'

const SearchAlert = () => {
  const t = useTranslations()

  const translatedAlertContent = t`A vacina contra a COVID 19 ainda não está disponível para a rede privada. Para mais informações acesse: `
  const translatedAlertLinkText = t`dasa.com.br/coronavirus`
  const translatedAlertLinkUrl = t`https://dasa.com.br/coronavirus`

  return (
    <S.AlertContainer>
      <S.AlertContent>
        &#42;{translatedAlertContent}
        <S.AlertLink href={translatedAlertLinkUrl} target="_blank" title={translatedAlertLinkText}>
          {translatedAlertLinkText}
        </S.AlertLink>
      </S.AlertContent>
    </S.AlertContainer>
  )
}

export default memo(SearchAlert)
