import styled from 'styled-components'
import media from 'styled-media-query'
import { CloseOutline } from '@styled-icons/evaicons-outline'

export const BudgetDocumentContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: fixed;
  z-index: 500000;
  width: 36%;
  height: 80%;
  top: 10%;
  left: 32%;
  -webkit-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 11px 4px rgba(0, 0, 0, 0.75);

  ${media.lessThan('medium')`
    width: 94%;
    height: 94vh;
    top: 3vh;
    left: 3%;
  `}
`

export const BudgetDocumentClose = styled.div`
  position: absolute;
  top: -1rem;
  left: -1rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 800;
  cursor: pointer;

  ${media.lessThan('medium')`
    width: 3.5rem;
    height: 3.5rem;
  `}
`

export const CloseIcon = styled(CloseOutline)`
  color: ${props => props.theme.colors.white};
  width: 2rem;
  height: auto;

  ${media.lessThan('medium')`
    width: 3rem;
  `}
`
