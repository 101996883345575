import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import { cartReducer } from './reducers'

const persistConfig = {
  key: 'root',
  storage: storageSession,
}

const persistedReducer = persistReducer(persistConfig, cartReducer)

const addDevtools = () => {
  // if (window && window.__REDUX_DEVTOOLS_EXTENSION__) {
  //   window.__REDUX_DEVTOOLS_EXTENSION__()
  // }
}

const store = createStore(persistedReducer, addDevtools())
const persistor = persistStore(store)

export { store, persistor }
