import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { CartPlus } from '@styled-icons/bootstrap/CartPlus'

import * as S from './styled'

const BudgetHeader = ({ budgetLength, onClick }) => {
  return (
    <S.BudgetHeaderContainer>
      <ReactTooltip place="left" type="dark" effect="solid" />

      <S.MiddleElementsHeaderContainer>
        <CartPlus size={24} />
        <S.BudgetTitle>
          Seu orçamento ({budgetLength} {budgetLength === 1 ? 'item' : 'itens'})
        </S.BudgetTitle>
      </S.MiddleElementsHeaderContainer>

      <S.CloseIcon onClick={onClick} data-tip="Fechar" />
    </S.BudgetHeaderContainer>
  )
}

BudgetHeader.propTypes = {
  budgetLength: PropTypes.number,
  onClick: PropTypes.func,
}

export default BudgetHeader
