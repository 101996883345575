import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../shared/container'

export const ProductDetailContainer = styled(Container)`
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
  display: grid;
  grid-template-columns: auto 33.6rem;
  gap: 14.4rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    margin-bottom: 3rem;
    margin-top: 3rem;
    grid-template-columns: 1fr;
    gap: 0;
  `}
`
