import React, { useContext } from 'react'
import PropTypes from 'prop-types'

const ProductContext = React.createContext({})

const ProductProvider = ({ productState, children }) => {
  return <ProductContext.Provider value={productState}>{children}</ProductContext.Provider>
}

ProductProvider.propTypes = {
  productState: PropTypes.object,
  children: PropTypes.element,
}

const useProductContext = () => useContext(ProductContext)

export { ProductProvider, useProductContext }
