import styled from 'styled-components'

export const BannerContainer = styled.div`
  margin-bottom: 1.4rem;
  grid-area: banner;
`

export const BannerImage = styled.img`
  width: 100%;
  border-radius: 0.8rem;
`

