import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const BudgetClearAllItems = ({ budgetItems, clearAllItems }) => {
  return (
    <>
      {budgetItems.length > 0 && (
        <S.ClearAllItemsContainer>
          <S.ClearAllItemsButtom onClick={clearAllItems}>Limpar itens</S.ClearAllItemsButtom>
        </S.ClearAllItemsContainer>
      )}
    </>
  )
}

BudgetClearAllItems.propTypes = {
  budgetItems: PropTypes.array,
  clearAllItems: PropTypes.func,
}

export default BudgetClearAllItems
