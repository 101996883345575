import React, { useState } from 'react'
import { formatTextToNumbers, isExternalLink } from '@cms/utils'

import * as S from './styled'

const ContactFilter = ({ settings }) => {
  const [regionState, setRegionState] = useState('sp')

  const handleContactFilteredPerState = e => {
    const contactFiltered = e.target.value
    setRegionState(contactFiltered)
  }

  const link = settings[`home_sched_link_${regionState}`]
  const phone = `tel:+55${formatTextToNumbers(settings[`phone_${regionState}`])}`
  const linkLabel = {
    sp: 'Agendar',
    rj: 'Agendar',
  }

  return (
    <>
      <S.StateTitle>Selecione a região</S.StateTitle>
      <S.StatesContainer>
        {['sp', 'rj'].map(state => (
          <div key={state}>
            <S.StatesInputRadio
              data-testid={`input-radio-${state}`}
              type="radio"
              id={state}
              name="stateSelected"
              value={state}
              onChange={handleContactFilteredPerState}
              defaultChecked={state === 'sp'}
            />
            <S.StatesInputLabel htmlFor={state}>{state}</S.StatesInputLabel>
          </div>
        ))}
      </S.StatesContainer>

      <S.SchedulePrimaryButton
        data-testid={`link-${regionState}`}
        href={link}
        target={isExternalLink(link)}
      >
        {linkLabel[regionState]}
      </S.SchedulePrimaryButton>
      <S.ScheduleSecondaryButton data-testid={`phone-${regionState}`} href={phone}>
        Ligar para agendar
      </S.ScheduleSecondaryButton>
    </>
  )
}

export default ContactFilter
