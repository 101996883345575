import React from 'react'
import PropTypes from 'prop-types'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer'
import { capitalize } from '@cms/utils'

const sumItems = items => {
  return items
    .reduce((total, item) => total + item.price, 0.0)
    .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
}

const BudgetDocument = ({ cartItems, logo }) => {
  const now = new Date()
  return (
    <Document title="orçamento">
      <Page style={styles.body} size="A4" wrap>
        <View style={styles.header} fixed>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.title}>Orçamento de produtos</Text>
        </View>

        {cartItems.map(item => (
          <View style={styles.item} key={item.id} wrap={false}>
            <View style={styles.highlight}>
              <Text style={styles.name}>{item.name}</Text>
              <Text>
                {item.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </Text>
            </View>
          </View>
        ))}

        <View style={styles.totalPrice}>
          <Text>Total: </Text>
          <Text style={styles.value}>{sumItems(cartItems)}</Text>
        </View>
        <View style={styles.deadline} fixed>
          <Text>
            Data do orçamento:{' '}
            <Text style={styles.date}>
              {now.getDate()}/{now.getMonth() + 1}/{now.getFullYear()}
            </Text>
          </Text>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
  ],
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 110,
    paddingHorizontal: 35,
    fontFamily: 'Open Sans',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    height: 80,
  },
  logo: {
    width: 175,
  },
  title: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  item: {
    fontSize: 13,
    borderBottom: '1 solid black',
    marginTop: 15,
    paddingBottom: 15,
  },
  highlight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  name: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  totalPrice: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textTransform: 'uppercase',
    paddingVertical: 25,
    borderBottom: '1 solid black',
  },
  value: {
    fontWeight: 700,
  },
  deadline: {
    position: 'absolute',
    fontSize: 13,
    left: 35,
    bottom: 60,
  },
  date: {
    fontWeight: 700,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 35,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
})

BudgetDocument.propTypes = {
  cartItems: PropTypes.array,
  logo: PropTypes.string,
}

export default BudgetDocument
