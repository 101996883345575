import AtividadeFisica from '../../assets/images/atividade_fisica.svg'
import Default from '../../assets/images/default.svg'
import InformacaoAgendamento from '../../assets/images/informacao_agendamento.svg'
import InformacaoJejum from '../../assets/images/informacao_jejum.svg'
import RestricaoIdade from '../../assets/images/restricao_idade.svg'
import HorarioColeta from '../../assets/images/horario_coleta.svg'
import Prazo from '../../assets/images/prazo.svg'
import CoberturaAns from '../../assets/images/cobertura_ans.svg'
import NaoCoberturaAns from '../../assets/images/nao_cobertura_ans.svg'

const getIcon = type => {
  const info = {
    atividade_fisica: () => AtividadeFisica.id,
    default: () => Default.id,
    informacao_agendamento: () => InformacaoAgendamento.id,
    informacao_jejum: () => InformacaoJejum.id,
    jejum_maximo: () => InformacaoJejum.id,
    jejum_minimo: () => InformacaoJejum.id,
    restricao_idade: () => RestricaoIdade.id,
    horario_coleta: () => HorarioColeta.id,
    prazo: () => Prazo.id,
    cobertura_ans: () => CoberturaAns.id,
    nao_cobertura_ans: () => NaoCoberturaAns.id,
  }
  return (info[type] || info.default)()
}

export default getIcon
