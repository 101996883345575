import styled from 'styled-components'

export const BudgetItemsBadge = styled.div`
  position: absolute;
  top: -1.6rem;
  left: -1.6rem;
  width: 3.2rem;
  height: 3.2rem;
  background-color: #10a823;
  color: ${props => props.theme.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 800;
`
