import styled from 'styled-components'
import { CartPlus } from '@styled-icons/bootstrap/CartPlus'

export const BudgetFloatingButtonContainer = styled.div`
  right: 0;
  position: fixed;
  margin-top: 2.1rem;
  background-color: ${props => props.theme.colors.primary};
  width: 4rem;
  height: 4rem;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
`

export const BudgetIcon = styled(CartPlus)`
  width: 3rem;
  height: 3rem;
  color: ${props => props.theme.colors.white};
`
