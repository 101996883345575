import styled from 'styled-components'
import media from 'styled-media-query'
import { InfoCircle } from '@styled-icons/boxicons-regular'

export const AnsCoverContainer = styled.div`
  display: grid;
  grid-template-columns: 0.25fr 5fr;
  row-gap: 0.8rem;
  margin-bottom: 3.8rem;

  ${media.lessThan('medium')`
    grid-template-columns: 0.5fr 5fr;
    row-gap: 1.6rem;
    margin-bottom: 2.4rem;
  `}
`

export const AnsIcon = styled.svg`
  grid-column: 1;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.6rem;
`

export const AnsCoverTitleWrapper = styled.div`
  grid-column: 2;
  display: flex;
  align-items: center;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};

  ${media.lessThan('medium')`
    justify-content: space-between;
  `}
`

export const AnsCoverTitle = styled.h2`
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: normal;
  margin: 0;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};

  ${media.lessThan('medium')`
    font-size: 2rem;
    line-height: 2.4rem;
    width: 80%;
  `}
`

export const AnsTooltipContainer = styled.span`
  text-transform: capitalize;
  margin-left: 0.8rem;
`

export const AnsCoverInfoIcon = styled(InfoCircle)`
  width: 2.4rem;
  height: 2.4rem;
  text-transform: lowercase;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  cursor: pointer;

  ${media.lessThan('medium')`
    width: 2.64rem;
    height: 2.64rem;
  `}
`

export const AnsCoverInfo = styled.p`
  grid-column: 2;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  margin: 0;
`
