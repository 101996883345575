import axios from 'axios'

const send = async (blob, email) => {
  const data = new FormData()

  data.append('brand', process.env.GATSBY_BRAND_STRING_ID)
  data.append('brandName', process.env.GATSBY_BRAND_NAME)
  data.append('dest', email)
  data.append('file', blob)

  const config = {
    header: {
      'Content-Type': 'multipart/form-data',
    },
  }

  const response = await axios.post(process.env.GATSBY_BUDGET_API_URL, data, config)

  return response
}

export default { send }
