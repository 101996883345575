import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Banner = ({ data }) => {
  return (
    <S.SizeContainer>
      <S.BannerContainer href={data.link_url} target="_blank" data-testid="link">
        <S.ImageDesktop src={data.image_desktop.localFile.url} />
        <S.ImageMobile src={data.image_mobile.localFile.url} />
      </S.BannerContainer>
    </S.SizeContainer>
  )
}

Banner.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Banner
