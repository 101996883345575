import React from 'react'
import { Provider } from 'react-redux'

import { store, persistor } from '../state/createStore'

import { PersistGate } from 'redux-persist/integration/react'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}
