import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import { fadeInRight } from 'react-animations'
import { Link } from 'gatsby'
import { CloseOutline } from '@styled-icons/evaicons-outline'
import { ClipboardList } from '@styled-icons/fa-solid'
import { Trash } from '@styled-icons/boxicons-solid/Trash'
import { Printer } from '@styled-icons/icomoon/Printer'
import { Mail } from '@styled-icons/entypo/Mail'
import { FilePdf } from '@styled-icons/boxicons-solid'

const fadeAnimation = keyframes`${fadeInRight}`

export const BudgetBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  z-index: 5000;
  opacity: 0.5;
`

export const BudgetInfoContainer = styled.div`
  animation: 0.5s ${fadeAnimation};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  width: 38.5rem;
  right: 0;
  top: 0;
  height: 100vh;
  background-color: ${props => props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 5) 0px 1px 5px 0px;
  opacity: 1;
  z-index: 300000;

  ${media.lessThan('medium')`
    width: 80%;
    height: 100vh;
  `}
`
// HEADER SECTION

export const BudgetHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  width: 100%;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 1rem 2rem;

  ${media.lessThan('medium')`
    padding: 2rem;
  `}
`

export const MiddleElementsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 0.8rem;
`

export const BudgetIcon = styled(ClipboardList)`
  width: 2rem;
  height: 3rem;
  color: ${props => props.theme.colors.white};
`

export const BudgetTitle = styled.h2`
  font-size: 1.6rem;
  margin: 0;

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

export const CloseIcon = styled(CloseOutline)`
  color: ${props => props.theme.colors.white};
  width: 3rem;
  height: auto;
  cursor: pointer;
`

// ITEMS SECTION

export const BudgetListContainer = styled.div`
  height: inherit;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.lightGray};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.beigeGray};
  }
`

export const NoListItems = styled.p`
  margin: auto;
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.4rem;

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

export const ItemContainer = styled.div`
  margin: 2.5rem 2rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${props => props.theme.colors.mediumGray};
  &:last-child {
    border-bottom: none;
  }
`

export const RemoveItemButtom = styled.button`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
`

export const RemoveItemIcon = styled(Trash)`
  color: ${props => props.theme.colors.beigeGray};
  width: 2rem;
  height: auto;
  cursor: pointer;
`

export const NameAndRemoveItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ItemDetails = styled.p`
  font-size: 1.3rem;
  margin: 0.8rem 0;

  ${media.lessThan('medium')`
    font-size: 1.5rem;
  `}
`

export const ItemName = styled(Link)`
  color: ${props => props.theme.colors.beigeGray};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.3rem;
  margin: 0;
  ${media.lessThan('medium')`
    font-size: 1.5rem;
  `}
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
`

export const ItemPriceContainer = styled.div`
  display: flex;
`

export const ItemPriceLabel = styled(ItemDetails)`
  color: ${props => props.theme.colors.beigeGray};
  margin-right: 0.4rem;
`

export const ItemPrice = styled(ItemDetails)`
  color: ${props => props.theme.colors.primary};
`

// CLEAR ALL ITEMS SECTION

export const ClearAllItemsContainer = styled.div`
  height: auto;
  margin-top: 1rem;
  align-self: center;
`

export const ClearAllItemsButtom = styled.div`
  width: max-content;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.theme.colors.beigeGray};
  text-decoration: underline;
  opacity: 0.8;
  font-size: 1.2rem;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    font-size: 1.4rem;
  `}
`

// TOTAL VALUE SECTION

export const BudgetTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 1.6rem;
`

export const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.mediumGray};
  border-bottom: 1px solid ${props => props.theme.colors.mediumGray};
  padding: 1.5rem 0;
  margin: 1rem 0;
`

export const TotalLabel = styled.p`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${props => props.theme.colors.beigeGray};
  margin: 0;

  ${media.lessThan('medium')`
    font-size: 1.8rem 0;
  `}
`

export const TotalValue = styled.span`
  color: ${props => props.theme.colors.primary};
  margin-left: 5px;
  font-weight: 800;
`

export const TotalValueDisclaimer = styled.span`
  display: block;
  max-width: 29rem;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.2rem;
`

// FOOTER SECTION (EXPORT, BUTTONS)

export const BudgetFooter = styled.div`
  margin: 1rem 2rem 4rem;
`

// EXPORT SECTION

export const BudgetExportContainer = styled.div`
  margin: 0;
  display: flex;
  justify-content: space-around;
`

export const EmailButton = styled.button`
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.85;
  outline: none;
  opacity: ${props => (props.enabled ? '0.9' : '0.5')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    opacity: ${props => (props.enabled ? '1' : '0.5')};
  `}
`

export const PrintButton = styled.button`
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.85;
  outline: none;
  opacity: ${props => (props.enabled ? '0.9' : '0.5')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const DownloadButtonContainer = styled.div`
  opacity: ${props => (props.enabled ? '1' : '0.5')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};

  ${media.greaterThan('medium')`
    display: none;
  `}
`

export const DownloadButton = styled.button`
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.85;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    text-decoration: underline;
  }
`

export const PrinterIcon = styled(Printer)`
  color: ${props => props.theme.colors.beigeGray};
  margin-right: 0.5rem;
  width: 1.8rem;
  height: auto;
`

export const DownloadIcon = styled(FilePdf)`
  color: ${props => props.theme.colors.beigeGray};
  margin-right: 0.5rem;
  width: 1.8rem;
  height: auto;
`

export const MailIcon = styled(Mail)`
  color: ${props => props.theme.colors.beigeGray};
  margin-right: 0.5rem;
  width: 1.8rem;
  height: auto;
`

// FOOTER BUTTONS SECTION

export const ButtonsContainer = styled.div`
  margin-top: 2rem;
`

export const SearchMoreButton = styled(Link)`
  display: block;
  text-align: center;
  padding: 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: opacity, box-shadow;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  margin-bottom: 1.5rem;
`

export const ScheduleButton = styled.a`
  display: block;
  text-align: center;
  padding: 1.5rem;
  border-radius: 4px;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: opacity, box-shadow;
  margin-bottom: 1.5rem;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  opacity: ${props => (props.enabled ? '0.9' : '0.5')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};
  ${media.lessThan('medium')`
    font-size: 1.6rem;
    opacity: ${props => (props.enabled ? '1' : '0.5')};
  `}
`

export const NavLink = styled.a`
  display: block;
  margin-top: 3.2rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`

// SEND E-MAIL

export const EmailContainer = styled.div`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2.5rem;
`
export const SendEmalTitle = styled.p`
  font-size: 1.3rem;
  color: ${props => props.theme.colors.beigeGray};

  ${media.lessThan('medium')`
    font-size: 1.5rem;
  `}
`

export const SendEmailInputContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colors.beigeGray};
  height: 4rem;
  border-radius: 4px;
`

export const EmailIconInput = styled(MailIcon)`
  width: 2rem;
  margin: 0 1rem;
`
export const SendEmailInput = styled.input`
  font-size: 1.3rem;
  padding: 1.2rem 0;
  box-sizing: border-box;
  width: 100%;
  border: none;
  outline: none;
  color: ${props => props.theme.colors.beigeGray};

  &::placeholder {
    font-style: italic;
    color: ${props => props.theme.colors.mediumGray};
  }

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

export const SendEmailActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`

export const SendEmailButton = styled.button`
  font-size: 1.3rem;
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: ${props => props.theme.colors.beigeGray};
  cursor: pointer;
  outline: none;
  opacity: ${props => (props.disabled ? '0.5' : '0.9')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  padding: 0;
  margin-right: 2rem;

  &::hover {
    opacity: 1;
  }

  &:last-of-type {
    color: ${props => props.theme.colors.primary};
    margin-right: 0;
  }

  ${media.lessThan('medium')`
    font-size: 1.6rem;
    opacity: ${props => (props.disabled ? '0.5' : '1')};
  `}
`

export const EmailSucessMessage = styled.p`
  font-size: 1.4rem;
  color: #10a823;
  text-align: center;
  margin-bottom: 4rem;

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

export const EmailErrorMessage = styled.p`
  font-size: 1.4rem;
  color: red;
  text-align: center;
  margin-bottom: 4rem;

  ${media.lessThan('medium')`
    font-size: 1.6rem;
  `}
`

export const BudgetBackButton = styled.button`
  width: max-content;
  align-self: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: ${props => props.theme.colors.beigeGray};
  text-decoration: underline;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

export const SendingMailContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const OnlineWarning = styled.p`
  color: black;
  font-size: 1.6rem;
  line-height: 1.9rem;
`
