import styled from 'styled-components'
import media from 'styled-media-query'
import { SearchOutline } from '@styled-icons/evaicons-outline/SearchOutline'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

export const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 2.4rem;
  grid-area: search;

  ${media.lessThan('medium')`
    margin-top: 0;
    margin-bottom: 4rem;
  `}
`

export const SearchForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #565656;
  background: white;
  margin: 0 2.4rem;
  padding-bottom: 0.5rem;

  ${media.lessThan('medium')`
    margin: 0;
  `}
`

export const LoadingContainer = styled.div`
  width: 4rem;
  ${media.lessThan('medium')`
    display: none;
  `}
`

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  padding: 0;
  background: transparent;
  text-indent: 1rem;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.beigeGray};

  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-moz-placeholder {
    font-style: italic;
  }
  ::-moz-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
    font-style: italic;
  }
`

export const SearchIcon = styled(SearchOutline)`
  color: #565656;
  margin: 0 0 0 2rem;
`

export const CloseIcon = styled(CloseOutline)`
  color: ${props => props.theme.colors.beigeGray};
  /* margin-top: 1rem; */
  cursor: pointer;
`

export const AlertContainer = styled.div`
  text-align: center;
  margin-bottom: 4rem;
  margin-left: 4rem;
  margin-right: 4rem;

  color: ${props => props.theme.baseColors.error};

  grid-area: alert;
  font-size: 1.6rem;
  line-height: 2.2rem;

  ${media.lessThan('medium')`
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
    font-size: 1.4rem;
  `}
`

export const AlertContent = styled.p`
  font-weight: 700;
  margin: 0;
`

export const AlertLink = styled.a`
  font-weight: 700;
  color: ${props => props.theme.baseColors.error};
`
