import styled from 'styled-components'
import media from 'styled-media-query'

export const Aside = styled.div``

export const PriceContainer = styled.div`
  border-radius: 4px;
  padding: 2.5rem 2rem;
  background-color: rgba(125, 117, 112, 0.05);
  margin-bottom: 2rem;
`

export const Pricelabel = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  color: #666666;
  margin-bottom: 0.8rem;
`

export const Price = styled.p`
  font-size: 2.6rem;
  color: ${props => props.theme.colors.primary};
  margin: 0;
`

export const AddBudgetButton = styled.button`
  display: block;
  width: 100%;
  padding: 1.8rem;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 3.2rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.6rem;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary};
  opacity: ${props => (props.enabled ? '0.9' : '0.5')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};
  outline: none;

  &:hover {
    opacity: 1;
  }

  svg {
    margin-right: 0.8rem;
  }

  ${media.lessThan('medium')`
    opacity: ${props => (props.enabled ? '1' : '0.5')};
  `}
`

export const ScheduleInfoBox = styled.div`
  display: 'block';
  margin-top: 3rem;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.beigeGray};
  padding: 1rem;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.colors.white};
`

export const OnlineWarning = styled.p`
  color: black;
  font-size: 1.3rem;
  line-height: 1.9rem;
`
