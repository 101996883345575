import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const BudgetActionButtons = ({
  pageType,
  budgetItems,
  onClick,
  schedulingLink,
  schedulingLinkNav,
  brand,
  nav,
  showButtomSearchExam,
  showButtonSearchVaccine,
}) => {
  const brandId = process.env.GATSBY_BRAND_ID ? process.env.GATSBY_BRAND_ID.split(',')[0] : ''
  const nomeDaMarca = brand
  const nomeNavMarca = nav
  let scheduleLink
  let scheduleLinkVaccines
  let healthInsurenceCoverageLink

  const schedulingOnlineParams = budgetItems.reduce(
    (param, item) => `${param}${param === '' ? '' : ','}${item.id}`,
    ''
  )

  const schedulingParamsExamesToNav = budgetItems
    .filter(item => item.slug.includes('exames'))
    .reduce((param, exam) => `${param}${param === '' ? '' : ','}${exam.id}`, '')

  const schedulingParamsVaccinesToNav = budgetItems
    .filter(item => item.slug.includes('vacinas'))
    .reduce((param, vaccine) => `${param}${param === '' ? '' : ','}${vaccine.id}`, '')

  if (schedulingLink) {
    scheduleLink = `${schedulingLink}/exames/agendamento/adicionar?exames=${schedulingOnlineParams}`
  } else if (schedulingLinkNav) {
    scheduleLink = `${schedulingLinkNav}/entrar?laboratorio=${nomeNavMarca}&redirectTo=/agd-exames/paciente&brandId=${brandId}&productIds=${schedulingParamsExamesToNav}&dasa_source=${nomeDaMarca}&dasa_medium=site&dasa_campaign=agd-digital-sem_redirecionamento`
    scheduleLinkVaccines = `${schedulingLinkNav}/entrar?laboratorio=${nomeNavMarca}&redirectTo=/vacinas/paciente&brandId=${brandId}&productIds=${schedulingParamsVaccinesToNav}&dasa_source=${nomeDaMarca}&dasa_medium=site&dasa_campaign=agd-digital-sem_redirecionamento`
    healthInsurenceCoverageLink = `${schedulingLinkNav}/entrar?laboratorio=${nomeNavMarca}&redirectTo=/${
      pageType === 'exame' ? 'agd-exames' : 'vacinas'
    }/paciente&brandId=${brandId}&dasa_source=${nomeDaMarca}&dasa_medium=site&dasa_campaign=agd-digital-sem_redirecionamento`
  }

  return (
    <S.ButtonsContainer>
      {showButtomSearchExam && (
        <S.SearchMoreButton
          data-item-text="Adicionar mais exames"
          data-item-position="Carrinho"
          data-current-service="Agendamento de Exames"
          to="/exames"
          onClick={onClick}
        >
          Adicionar mais exames
        </S.SearchMoreButton>
      )}
      {showButtonSearchVaccine && (
        <S.SearchMoreButton
          data-item-text="Adicionar mais vacinas"
          data-item-position="Carrinho"
          data-current-service="Agendamento de Vacinas"
          to="/vacinas"
          onClick={onClick}
        >
          Adicionar mais vacinas
        </S.SearchMoreButton>
      )}

      {pageType === 'exame' && scheduleLink && (
        <S.ScheduleButton
          data-item-text="Agendar exames"
          data-item-position="Carrinho"
          data-current-service="Agendamento de Exames"
          href={scheduleLink}
          target="_blank"
          enabled={schedulingLinkNav ? schedulingParamsExamesToNav : budgetItems.length > 0}
        >
          {schedulingLinkNav ? 'Agendar exames' : 'Agendar'}
        </S.ScheduleButton>
      )}

      {pageType === 'vacina' && schedulingLinkNav && (
        <S.ScheduleButton
          data-item-text="Agendar vacinas"
          data-item-position="Carrinho"
          data-current-service="Agendamento de Vacinas"
          href={scheduleLinkVaccines}
          target="_blank"
          enabled={schedulingParamsVaccinesToNav}
        >
          Agendar vacinas
        </S.ScheduleButton>
      )}

      {healthInsurenceCoverageLink && (
        <S.NavLink
          data-item-text="Conferir cobertura de convênios"
          data-item-position="Carrinho"
          data-current-service={pageType === 'exame' ? 'Agendamento de Exames' : 'Agendamento de Vacinas'}
          href={healthInsurenceCoverageLink}
          target="_blank"
        >
          Conferir cobertura de convênios
        </S.NavLink>
      )}
    </S.ButtonsContainer>
  )
}

BudgetActionButtons.propTypes = {
  pageType: PropTypes.string,
  budgetItems: PropTypes.array,
  onClick: PropTypes.func,
  schedulingLink: PropTypes.string,
  showButtomSearchExam: PropTypes.bool,
  showButtonSearchVaccine: PropTypes.bool,
}

export default BudgetActionButtons
