import styled from 'styled-components'
import media from 'styled-media-query'

export const TitleContainer = styled.div``

export const Title = styled.h1`
  font-size: 4.2rem;
  line-height: 5rem;
  font-weight: normal;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};
  margin: 0 0 1.6rem;

  ${media.lessThan('medium')`
    font-size: 3.2rem;
    line-height: 4rem
  `}
`

export const Description = styled.div`
  width: 80%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 2.4rem;
    line-height: 2.4rem;
    font-weight: normal;
    color: ${props => props.theme.colors.black};
    margin: 0 0 0.8rem;

    ${media.lessThan('medium')`
      line-height: 3.2rem;
      margin: 0 0 0.8rem;
    `}
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: normal;
    color: ${props => props.theme.colors.beigeGray};
    margin: 0;
  }

  strong {
    font-weight: normal;
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}
`
