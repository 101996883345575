import styled from 'styled-components'
import media from 'styled-media-query'

export const ProductNameContainer = styled.div`
  margin-bottom: 4rem;

  ${media.lessThan('medium')`
    margin-bottom: 3rem;
  `}
`

export const ProductName = styled.h1`
  font-size: 4.2rem;
  line-height: 5rem;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0 0 1rem;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  word-break: break-word;

  ${media.lessThan('medium')`
    font-size: 2.8rem;
    line-height: 4rem;
  `}
`

export const Synonymies = styled.p`
  font-size: 1.5rem;
  line-height: 2.3rem;
  font-style: italic;
  margin: 0;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  word-break: break-word;

  ${media.lessThan('medium')`
    font-size: 1.2rem;
  `}
`
