import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslations } from 'gatsby-plugin-translate'
import { CartDash } from '@styled-icons/bootstrap/CartDash'
import { CartPlus } from '@styled-icons/bootstrap/CartPlus'

import * as S from './styled'

const ProductsList = ({
  productsData,
  message,
  pageType,
  hasCart,
  setPageType,
  openCart,
  cartItemsExam,
  cartItemsVaccine,
  addCartItem,
  removeCartItem,
}) => {
  const t = useTranslations()

  useEffect(() => {
    const pageTypeValue = productsData[0]?.type
    if (!pageTypeValue) return
    setPageType(pageTypeValue)
  }, [productsData])

  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  if (productsData.length === 0 && message)
    return (
      <S.ProductsListContainer>
        <S.ResultsMessage>{message}</S.ResultsMessage>
      </S.ProductsListContainer>
    )

  const getProductLowerPrice = prices => {
    const lowerPrice = prices
      .map(price => price.exames[0].preco)
      .filter(price => price !== 0)
      .sort(function (a, b) {
        return a - b
      })[0]

    return lowerPrice
  }

  const addOrRemoveBudgetItem = product => {
    if (!product.unityPrice.length) return

    const budgetItem = {
      id: product.id,
      name: product.nome,
      slug: `/${product.type}s/${product.slug}`,
      price: getProductLowerPrice(product.unityPrice),
    }

    if (productAlreadyInCart(product.id)) {
      return removeCartItem(budgetItem)
    }

    openCart()
    addCartItem(budgetItem)
  }

  const productAlreadyInCart = productId => {
    return !!cartItems.find(item => item.id === productId)
  }

  const isPriceValid = product => {
    return !!product.unityPrice?.length && !!getProductLowerPrice(product.unityPrice)
  }

  return (
    <S.ProductsListContainer>
      {productsData.map((product, index) => (
        <S.ProductsListItem key={index}>
          <S.ProductInfo>
            <S.ProductName>{product.nome}</S.ProductName>
            {product.sinonimo !== undefined &&
              product.sinonimo !== '' &&
              product.sinonimo.length > 0 && (
                <S.ProductSynonym>
                  {t`É igual a`}{' '}
                  {product.sinonimo.length > 7
                    ? `${product.sinonimo.slice(0, 7).join(', ')} ...`
                    : product.sinonimo.join(', ')}
                </S.ProductSynonym>
              )}

            {hasCart && isPriceValid(product) && (
              <S.ProductPriceWrapper data-testid="product-price-wrapper">
                <span>A PARTIR DE:</span>
                <span>
                  {getProductLowerPrice(product.unityPrice).toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </S.ProductPriceWrapper>
            )}
          </S.ProductInfo>

          {hasCart ? (
            <S.ButtonsWrapper data-testid="cart-buttons">
              {productAlreadyInCart(product.id) ? (
                <S.AddToCartButton
                  data-item-text={`Remover do carrinho | ${product.nome}`}
                  data-item-position="Card"
                  data-current-service={
                    pageType === 'exame' ? 'Agendamento de Exames' : 'Agendamento de Vacinas'
                  }
                  onClick={() => addOrRemoveBudgetItem(product)}
                  data-tip="Produto já adicionado ao carrinho."
                  enabled
                >
                  <CartDash size={24} />
                  <span>Remover</span>
                </S.AddToCartButton>
              ) : (
                <S.AddToCartButton
                  data-item-text={`Adicionar ao carrinho | ${product.nome}`}
                  data-item-position="Card"
                  data-current-service={
                    pageType === 'exame' ? 'Agendamento de Exames' : 'Agendamento de Vacinas'
                  }
                  onClick={() => addOrRemoveBudgetItem(product)}
                  enabled={isPriceValid(product)}
                >
                  <CartPlus size={24} />
                  <span>Adicionar</span>
                </S.AddToCartButton>
              )}

              <S.ProductDetailsButton
                data-item-text={`Saiba mais | ${product.nome}`}
                data-item-position="Card"
                data-current-service={
                  pageType === 'exame' ? 'Agendamento de Exames' : 'Agendamento de Vacinas'
                }
                to={product.slug}
              >
                Saiba mais
              </S.ProductDetailsButton>
            </S.ButtonsWrapper>
          ) : (
            <S.ProductDetailsButtonWithoutCart to={product.slug}>
              Saiba mais
            </S.ProductDetailsButtonWithoutCart>
          )}
        </S.ProductsListItem>
      ))}
    </S.ProductsListContainer>
  )
}

ProductsList.propTypes = {
  productsData: PropTypes.arrayOf(
    PropTypes.shape({
      nome: PropTypes.string,
      slug: PropTypes.string,
      sinonimo: PropTypes.array,
    })
  ),
  message: PropTypes.string,
  pageType: PropTypes.string,
  hasCart: PropTypes.bool,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  addCartItem: PropTypes.func,
  removeCartItem: PropTypes.func,
  openCart: PropTypes.func,
  setPageType: PropTypes.func,
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine }) => {
  return { pageType, cartItemsExam, cartItemsVaccine }
}

const mapDispatchToProps = dispatch => {
  return {
    addCartItem: product => dispatch({ type: 'ADD_CART_ITEM', payload: product }),
    removeCartItem: product => dispatch({ type: 'REMOVE_CART_ITEM', payload: product }),
    openCart: () => dispatch({ type: 'OPEN_CART' }),
    setPageType: pageType => dispatch({ type: 'SET_PAGE_TYPE', payload: pageType }),
  }
}

const ConnectedProductsList = connect(mapStateToProps, mapDispatchToProps)(ProductsList)

export default ConnectedProductsList
