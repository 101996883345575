import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from 'gatsby-plugin-translate'
import { mediaQuery, debounce } from '@cms/utils'
import { useProductContext } from '../../../contexts'
import SyncLoader from 'react-spinners/SyncLoader'
import ReactTooltip from 'react-tooltip'

import * as S from './styled'

const Search = ({ onSearch, loading }) => {
  const {
    productsData: { type },
  } = useProductContext()
  const t = useTranslations()
  const [query, setQuery] = useState('')

  useEffect(() => {
    const trigger = localStorage.getItem('trigger-search')
    if (trigger) {
      localStorage.removeItem('trigger-search')
      const term = localStorage.getItem('current-search')
      if (term) {
        setQuery(term)
        onSearch(term)
      }
    } else {
      localStorage.removeItem('current-search')
    }
  }, [])

  const debouncedSearch = useCallback(debounce(onSearch, 500), [])

  const inputChange = e => {
    const term = e.target.value
    localStorage.setItem('current-search', term)
    setQuery(term)
    if (debouncedSearch && !mediaQuery.isMedium()) debouncedSearch(term)
  }

  const handleSubmit = e => {
    e.preventDefault()
    document.querySelector('#search-query').blur()
    onSearch(query)
  }

  const inputFocus = e => {
    if (mediaQuery.isMedium())
      window.scrollTo({ top: e.target.offsetTop - 60, left: 0, behavior: 'smooth' })
  }

  const clearSearch = () => {
    setQuery('')
    onSearch('')
    localStorage.removeItem('current-search')
  }

  const Close = () => (
    <>
      <S.CloseIcon onClick={clearSearch} size="26" data-tip="Limpar" />
      <ReactTooltip place="top" type="dark" effect="solid" />
    </>
  )

  return (
    <S.SearchContainer>
      <S.SearchForm
        data-item-text={type === 'exams' ? "Campo Busca Exame" : "Campo Busca Vacina"}
        data-item-position={type === 'exams' ? "Busca Exame" : "Busca Vacina"}
        data-current-service={type === 'exams' ? "Agendamento de Exames" : "Agendamento de Vacinas"}
        onSubmit={handleSubmit}
      >
        <S.SearchIcon size="26" />
        <S.SearchInput
          id="search-query"
          autoComplete="off"
          type="text"
          placeholder={type === 'exams' ? t`Qual exame procura?` : t`Qual vacina procura?`}
          value={query}
          onChange={inputChange}
          onFocus={inputFocus}
        />

        <S.LoadingContainer>
          <SyncLoader loading={loading} color="#aaa" size="6px" />
        </S.LoadingContainer>
        {query !== '' && !loading && <Close />}
      </S.SearchForm>
    </S.SearchContainer>
  )
}

Search.propTypes = {
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
}

export default Search
