import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import { fadeIn } from 'react-animations'
// import { Link } from 'gatsby'

const fadeAnimation = keyframes`${fadeIn}`

export const Aside = styled.div``

export const UnitsContainer = styled.div`
  padding: 3.2rem 2rem;
  border: 1px solid ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
`

export const UnitsListTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  margin: 0 0 1.8rem;
`

export const UnitsListContainer = styled.ul`
  margin: 0;
  padding: 0.5rem;
`

export const Unit = styled.li`
  list-style-type: none;
  font-size: 1.4rem;
  line-height: 1.71;
  cursor: pointer;
  margin: 1rem 0;
  width: fit-content;
`

export const UnitLink = styled.a`
  animation: 1s ${fadeAnimation};
  text-decoration: none;  
  font-size: 1.4rem;
  line-height: 1.71;
  cursor: pointer;
  margin: 1rem 0;
  width: fit-content;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};

  &:hover {
    color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  }
`

export const StatesContainer = styled.div`
  margin-bottom: 1rem;
`

export const StatesInputRadio = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  + label {
    position: relative;
    cursor: pointer;
    padding-left: 3rem;
  }

  + label::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
    vertical-align: bottom;
  }

  &:checked + label::after {
    content: '';
    background-color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    position: absolute;
    left: 0.4rem;
    bottom: 0.4rem;
  }
`

export const StatesInputLabel = styled.label`
  font-size: 1.6rem;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  cursor: pointer;

  &:first-of-type {
    margin-right: 5.4rem;
  }
`

export const ScheduleButton = styled.a`
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background-color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 1.2rem;
  border-radius: 2px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`
