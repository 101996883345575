import styled from 'styled-components'
import media from 'styled-media-query'

export const VaccinationContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};
  padding: 3.2rem;
  margin-bottom: 2.4rem;
  grid-area: vaccination;

  ${media.lessThan('medium')`
    padding: 2.4rem;
    margin-bottom: 6rem;
  `}
`

export const VaccinationTitle = styled.h3`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};
  margin: 0 0 1.6rem 0;
`

export const VaccinationInfo = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h5,
  p {
    margin: 0;
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: normal;
    color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : '#777474')};
  }
`
