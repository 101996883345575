const initialState = {
  pageType: 'exame',
  cartItemsExam: [],
  cartItemsVaccine: [],
  openCart: false,
}

const cartReducer = (state = initialState, { type, payload }) => {
  const { pageType, cartItemsExam, cartItemsVaccine, openCart } = state

  switch (type) {
    case 'SET_PAGE_TYPE':
      return {
        pageType: payload,
        cartItemsExam,
        cartItemsVaccine,
        openCart,
      }
    case 'ADD_CART_ITEM':
      return {
        pageType,
        cartItemsExam: pageType === 'exame' ? [...cartItemsExam, payload] : cartItemsExam,
        cartItemsVaccine: pageType === 'vacina' ? [...cartItemsVaccine, payload] : cartItemsVaccine,
        openCart,
      }
    case 'REMOVE_CART_ITEM':
      return {
        pageType,
        cartItemsExam:
          pageType === 'exame'
            ? cartItemsExam.filter(item => item.id !== payload.id)
            : cartItemsExam,
        cartItemsVaccine:
          pageType === 'vacina'
            ? cartItemsVaccine.filter(item => item.id !== payload.id)
            : cartItemsVaccine,
        openCart,
      }
    case 'OPEN_CART':
      return {
        pageType,
        cartItemsExam,
        cartItemsVaccine,
        openCart: true,
      }
    case 'NOTIFY_CART_OPEN':
      return {
        pageType,
        cartItemsExam,
        cartItemsVaccine,
        openCart: false,
      }
    case 'REMOVE_ALL_CART_ITEMS':
      return {
        pageType,
        cartItemsExam: pageType === 'exame' ? [] : cartItemsExam,
        cartItemsVaccine: pageType === 'vacina' ? [] : cartItemsVaccine,
        openCart,
      }
    default:
      return state
  }
}

export default cartReducer
