import React, { useState, useEffect } from 'react'
import { useTranslations } from 'gatsby-plugin-translate'

import { Paginator } from '@cms/components'
import { isMobile } from '@cms/utils'

import * as S from './styled'

import Title from './Title'
import ProductsList from './ProductsList'
import Search from './Search'
import TopProducts from './Aside/TopProducts'
import Vaccination from './Aside/Vaccination'
import ContactFilter from './Aside/ContactFilter'
import SearchAlert from './Search/SearchAlert'
import ProductBanner from './ProductBanner'

import { ProductService } from '../../services'
import { useProductContext } from '../../contexts'
import { VaccinationContainer, VaccinationInfo, VaccinationTitle } from './Aside/Vaccination'

const isAltaTheme = Number(process.env.GATSBY_BRAND_ID) === 19

const Products = ({ hasAlert = false }) => {
  const t = useTranslations()

  const translatedResultsMessage = t`Nenhum resultado encontrado para`
  const translatedResultsSearch = t`Resultados de sua Busca`
  const translatedSearching = t`Pesquisando`
  const translatedResultsfound = t`resultados encontrados`

  const {
    productsData: {
      type,
      showVaccination,
      vaccinationTitle,
      vaccinationInfo,
      bannerSidebar,
      products,
      settings,
      product_budget,
    },
  } = useProductContext()

  const [searchResult, setSearchResult] = useState(products)
  const [pagedResult, setPagedResult] = useState([])
  const [resultsMessage, setResultsMessage] = useState(translatedResultsSearch)
  const [pageResultsReport, setPageResultsReport] = useState('')
  const [loading, setLoading] = useState(false)

  const getProductsWithPrice = data => {
    if (isAltaTheme) return data

    return data.map(item => {
      const productWithPrice = products.find(({ id }) => id === String(item.id))
      if (!productWithPrice) return item
      return productWithPrice
    })
  }

  const onSearch = query => {
    if (isMobile(screen)) {
      setResultsMessage(translatedSearching)
      setSearchResult([])
    } else {
      setLoading(true)
    }

    if (query === '') {
      setResultsMessage(translatedResultsSearch)
      setSearchResult(products)
      setLoading(false)
    } else {
      ProductService.search(query, type).then(data => {
        setResultsMessage(data.length === 0 ? `${translatedResultsMessage} "${query}"` : null)

        const productsWithPrice = getProductsWithPrice(data)
        setSearchResult(productsWithPrice)

        setLoading(false)
      })
    }
  }

  const pageChanged = (pagedProducts, pageReport) => {
    setPagedResult(pagedProducts)
    setPageResultsReport(pageReport)
  }

  const screen = typeof window !== `undefined` ? window.screen : false

  const [viewTopSearchMobile, setViewTopSearchMobile] = useState(false)

  useEffect(() => {
    if (isMobile(screen)) {
      setViewTopSearchMobile(true)
    }
  }, [])

  return (
    <S.MainContainer altaTheme={isAltaTheme}>
      <Title />
      <S.ProductsContainer>
        {viewTopSearchMobile ? (
          <>
            {(type === 'vaccine' || type === 'exams') && showVaccination && (
              <VaccinationContainer>
                <VaccinationTitle title={vaccinationTitle} />
                <VaccinationInfo info={vaccinationInfo} />
                {isAltaTheme && <ContactFilter settings={settings} />}
              </VaccinationContainer>
            )}
            <Search onSearch={onSearch} loading={loading} />
            {hasAlert && <SearchAlert />}
            <ProductsList
              productsData={pagedResult}
              message={resultsMessage}
              hasCart={product_budget}
            />
            <ProductBanner
              active={bannerSidebar.active}
              link={bannerSidebar.link}
              image={bannerSidebar.imageMobile}
            />
            <TopProducts />
          </>
        ) : (
          <>
            <S.AsideContainer>
              <ProductBanner
                active={bannerSidebar.active}
                link={bannerSidebar.link}
                image={bannerSidebar.imageDesktop}
              />
              {(type === 'vaccine' || type === 'exams') && showVaccination && (
                <VaccinationContainer>
                  <VaccinationTitle title={vaccinationTitle} />
                  <VaccinationInfo info={vaccinationInfo} />
                  {isAltaTheme && <ContactFilter settings={settings} />}
                </VaccinationContainer>
              )}
              <TopProducts />
            </S.AsideContainer>
            <S.SearchResultsContainer>
              <Search onSearch={onSearch} loading={loading} />
              {hasAlert && <SearchAlert />}
              <ProductsList
                productsData={pagedResult}
                message={resultsMessage}
                hasCart={product_budget}
              />
            </S.SearchResultsContainer>
          </>
        )}

        <S.PaginatorContainer>
          <Paginator data={searchResult} onPageChange={pageChanged} pageSize={8} />
          {searchResult.length > 0 && (
            <S.ResultsRow>
              {pageResultsReport} {translatedResultsfound}
            </S.ResultsRow>
          )}
        </S.PaginatorContainer>
      </S.ProductsContainer>
    </S.MainContainer>
  )
}

export default Products
