import axios from 'axios'
import slug from 'slug'

const mapBudget = unit => {
  return {
    name: unit.nomeUnidade,
    id: unit.idUnidade,
    slug: `/unidades/${slug(unit.nomeUnidade)}/${unit.idUnidade}`,
    price: unit.exames[0].preco,
  }
}

const BudgetService = {
  getBudget: async product => {
    try {
      const multiDP = process.env.GATSBY_BRAND_ID.split(',')
      const resultado = await Promise.all(
        multiDP.map(async idDPMarca => {
          const url = `${process.env.GATSBY_DP_SEARCH_URL_BUDGET}?idMarca=${idDPMarca}&idSistema=14&produtos=${product}&size=100`
          const resultado = await axios.get(url)
          return resultado.status === 204 ? [] : resultado.data.content.map(mapBudget)
        })
      )
      const resultadoFinal = await resultado.reduce((list, sub) => list.concat(sub), [])
      return resultadoFinal
    } catch (error) {
      return []
    }
  },
}

export default BudgetService
