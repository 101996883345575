import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Paginator = ({ data, pageSize = 10, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [shownPages, setShownPages] = useState([])
  const [lastPage, setLastPage] = useState(0)
  const separator = '...'
  let totalPages = 0

  useEffect(() => {
    if (data.length > pageSize) {
      setLastPage(Math.ceil(data.length / pageSize))
      changePage(1)
    } else {
      onPageChange(data, `1 - ${data.length} de ${data.length}`)
    }
  }, [data])

  const changePage = page => {
    totalPages = Math.ceil(data.length / pageSize)
    const pagedData = sliceData(data, page)
    setCurrentPage(page)
    onPageChange(pagedData, formatReportMessage(page))
    arrangeAvailablePages(page)

    const isFirefox = typeof InstallTrigger !== 'undefined'
    window.scrollTo({ top: 0, left: 0, behavior: isFirefox ? 'auto' : 'smooth' })
  }

  const formatReportMessage = page => {
    const lastPageResults = pageSize * page < data.length ? pageSize * page : data.length
    const initial = `${page === 1 ? '1' : (page - 1) * pageSize + 1}`
    const final = `${page === 1 ? pageSize : lastPageResults}`
    return `${initial} - ${final} de ${data.length}`
  }

  const sliceData = (fullDataset, page) => {
    const sliceStart = (page - 1) * pageSize
    const sliceEnd = sliceStart + pageSize
    return fullDataset.slice(sliceStart, sliceEnd)
  }

  const arrangeAvailablePages = current => {
    const delta = 1
    const range = []
    const rangeWithDots = []
    let l

    range.push(1)
    for (let i = current - delta; i <= current + delta; i += 1) {
      if (i < totalPages && i > 1) {
        range.push(i)
      }
    }
    range.push(totalPages)

    for (const i of range) {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1)
        } else if (i - l !== 1) {
          rangeWithDots.push(separator)
        }
      }
      rangeWithDots.push(i)
      l = i
    }

    setShownPages(rangeWithDots)
  }

  const movePrevious = () => {
    changePage(currentPage - 1)
  }

  const moveNext = () => {
    changePage(currentPage + 1)
  }

  return (
    <S.PaginationContainer visible={data.length > pageSize}>
      <S.ButtonLeft size={45} firstPage={currentPage === 1} onClick={movePrevious} />
      {shownPages.map(pageMarker =>
        pageMarker === currentPage || pageMarker === separator ? (
          <S.PageNumber key={pageMarker} active={pageMarker === currentPage}>
            {pageMarker}
          </S.PageNumber>
        ) : (
          <S.PageNumber key={pageMarker} onClick={() => changePage(pageMarker)}>
            {pageMarker}
          </S.PageNumber>
        )
      )}
      <S.ButtonRight size={45} lastPage={currentPage === lastPage} onClick={moveNext} />
    </S.PaginationContainer>
  )
}

Paginator.propTypes = {
  data: PropTypes.array.isRequired,
  pageSize: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
}

export default Paginator
