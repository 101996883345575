import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
  margin: ${props => (props.altaTheme ? '4.8rem 9.6rem' : '0 auto')};
  width: ${props => (props.altaTheme ? 'inherit' : '141.4rem')};
  max-width: ${props => (props.altaTheme ? 'inherit' : 'calc(100% - 6rem)')};

  ${media.lessThan('medium')`
    margin: ${props => (props.altaTheme ? '0 2.4rem' : '0 auto')};
    max-width: calc(100% - 4rem);
  `};
`
