import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import { fadeIn } from 'react-animations'
import { TranslateLink } from 'gatsby-plugin-translate'

const fadeAnimation = keyframes`${fadeIn}`

export const ProductsListContainer = styled.div`
  width: 100%;
  grid-area: result;

  ${media.lessThan('medium')`
    margin: 0 0 5rem 0;
    border:none;
  `}
`

export const ProductsListItem = styled.div`
  animation: 1s ${fadeAnimation};
  height: auto;
  width: 100%;
  border-radius: 4px;
  padding: 2.4rem;
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  ${media.lessThan('medium')`
    margin-bottom: 2.4rem;
    flex-direction: column;
    align-items: flex-end;
  `}
`

export const ProductInfo = styled.div`
  ${media.greaterThan('medium')`
      width: inherit;
  `}
  ${media.lessThan('medium')`
      width: inherit;
  `}
`

export const ProductName = styled.h3`
  color: ${props => props.theme.colors.greyishBrown};
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: normal;
  margin: 0 2.4rem 0.8rem 0;
  word-break: break-word;

  ${media.lessThan('medium')`
    margin: 0;
    font-size: 2rem;
    line-height: 2.8rem;
  `}
`

export const ProductSynonym = styled.p`
  color: ${props => props.theme.colors.brownishGrey};
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 0;
  margin-right: 3rem;
  word-break: break-word;

  ${media.lessThan('medium')`
    margin-right: 0;
    margin-top: 0.8rem;
    font-size: 1.2rem;
    line-height: 2rem;
  `}
`

export const ProductPriceWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 1.6rem;

  span {
    font-size: 1.4rem;
    font-weight: 500;
    color: #666;

    &:last-child {
      color: ${props => props.theme.colors.primary};
    }
  }
`

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.6rem;

  ${media.lessThan('medium')`
    margin-top: 1.6rem;
    gap: 0.8rem;
  `}
`

export const AddToCartButton = styled.button`
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  border-radius: 4px;
  max-width: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.2rem 0;
  cursor: pointer;
  background-color: ${props =>
    props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  opacity: ${props => (props.enabled ? '0.9' : '0.5')};
  pointer-events: ${props => (props.enabled ? 'all' : 'none')};

  &:hover {
    opacity: 1;
  }

  svg {
    margin-right: 0.8rem;
  }
`

export const ProductDetailsButton = styled(TranslateLink)`
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 700;
  border-radius: 4px;
  max-width: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.2rem 0;
  cursor: pointer;
  pointer-events: all;
  background-color: ${props => props.theme.colors.white};
  color: ${props =>
    props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary};
  border: 1px solid
    ${props =>
      props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary};
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`

export const ProductDetailsButtonWithoutCart = styled(TranslateLink)`
  border: 0;
  text-decoration: none;
  font-size: 1.5rem;
  line-height: 2.3rem;
  font-weight: normal;
  border-radius: 2px;
  min-width: 16rem;
  text-align: center;
  padding: 1.2rem 0;
  cursor: pointer;
  pointer-events: all;
  background-color: ${props =>
    props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    margin-top: 2.4rem;
  `}
`

export const ResultsMessage = styled.div`
  animation: 1s ${fadeAnimation};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.6rem;
  ${media.lessThan('medium')`
   margin-top: 0;
  `}
`
