import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from 'gatsby-plugin-translate'

import * as S from './styled'

const ScheduleButton = ({
  pageType,
  schedulingLink,
  schedulingLinkNav,
  id,
  brand,
  nav,
  productSettings,
  productName
}) => {
  const brandId = process.env.GATSBY_BRAND_ID ? process.env.GATSBY_BRAND_ID.split(',')[0] : ''
  const nomeDaMarca = brand
  const nomeNavMarca = nav
  let scheduleLink = ''

  const dataItemText = {
    exame: `Agendar Exame | ${productName}`,
    vacina: `Agendar Vacina | ${productName}`,
  }

  const dataCurrentService = {
    exame: "Agendamento de Exames | Exame",
    vacina:"Agendamento de Vacinas | Vacina",
  }

  if (schedulingLink !== '' && schedulingLink !== null) {
    scheduleLink =
      schedulingLink?.slice(-1) === '/'
        ? schedulingLink + 'exames/agendamento/adicionar?exames=' + id
        : schedulingLink + '/exames/agendamento/adicionar?exames=' + id
  } else if (schedulingLinkNav !== '' && schedulingLinkNav !== null) {
    let urlType = 'agd-exames'
    if (typeof window !== 'undefined') {
      urlType = window.location.href.includes('vacina') ? 'vacinas' : urlType
    }
    scheduleLink = `${schedulingLinkNav}/entrar?laboratorio=${nomeNavMarca}&redirectTo=/${urlType}/paciente&brandId=${brandId}&productIds=${id}&dasa_source=${nomeDaMarca}&dasa_medium=site&dasa_campaign=agd-digital-sem_redirecionamento`
  }

  return (
    <>
      {Number(brandId) === 19 ? null : (
        <>
          {productSettings.enable_scheduling ? (
            <S.OnlineSchedule
              data-item-text={dataItemText[pageType]}
              data-item-position="Card"
              data-current-service={dataCurrentService[pageType]}
              data-testid="link"
              href={scheduleLink}>
              <Translate>{`${productSettings.scheduling_button_label} ${pageType}`}</Translate>
            </S.OnlineSchedule>
          ) : (
            <S.ScheduleInfo>
              <Translate>{productSettings.scheduling_information_text}</Translate>
            </S.ScheduleInfo>
          )}
        </>
      )}
    </>
  )
}

ScheduleButton.propTypes = {
  scheduling: PropTypes.bool,
  schedulingLink: PropTypes.string,
  pageType: PropTypes.string,
}

export default ScheduleButton
