import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

import * as S from './styled'

const BudgetList = ({ budgetItems, removeBudgetItem, onClick }) => {
  return (
    <S.BudgetListContainer>
      <ReactTooltip place="left" type="dark" effect="solid" />
      {budgetItems.length ? (
        budgetItems.map(item => (
          <S.ItemContainer key={item.id}>
            <S.NameAndRemoveItemContainer>
              <S.ItemName to={item.slug} data-tip="Detalhes do produto" onClick={onClick}>
                {item.name}
              </S.ItemName>

              <S.RemoveItemButtom onClick={removeBudgetItem(item)} data-tip="Excluir do orçamento">
                <S.RemoveItemIcon />
              </S.RemoveItemButtom>
            </S.NameAndRemoveItemContainer>

            <S.ItemPriceContainer>
              <S.ItemPriceLabel>A PARTIR DE: </S.ItemPriceLabel>
              <S.ItemPrice>
                {item.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
              </S.ItemPrice>
            </S.ItemPriceContainer>
          </S.ItemContainer>
        ))
      ) : (
        <S.NoListItems>Você ainda não adicionou produtos.</S.NoListItems>
      )}
    </S.BudgetListContainer>
  )
}

BudgetList.propTypes = {
  budgetItems: PropTypes.array,
  removeBudgetItem: PropTypes.func,
  onClick: PropTypes.func,
}

export default BudgetList
