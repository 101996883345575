import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const BudgetTotal = ({ total }) => {
  return (
    <S.BudgetTotalContainer>
      <S.TotalPrice>
        <S.TotalLabel>
          Total: <S.TotalValue>{total}</S.TotalValue>
        </S.TotalLabel>
      </S.TotalPrice>

      <S.TotalValueDisclaimer>
        O valor acima é uma estimativa e pode variar de acordo com a unidade.
      </S.TotalValueDisclaimer>
    </S.BudgetTotalContainer>
  )
}

BudgetTotal.propTypes = {
  total: PropTypes.string,
}

export default BudgetTotal
