import styled from 'styled-components'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@styled-icons/material'
import media from 'styled-media-query'

export const PaginationContainer = styled.div`
  justify-content: center;
  font-size: 1.6rem;
  align-items: center;
  display: ${props => (props.visible ? 'flex' : 'none')};
`

export const ButtonLeft = styled(KeyboardArrowLeft)`
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  display: ${props => (props.firstPage ? 'none' : 'block')};
  cursor: pointer;
  pointer-events: auto;
  margin-right: 3rem;
  ${media.lessThan('medium')`
    margin-right: 0;
  `}
`

export const ButtonRight = styled(KeyboardArrowRight)`
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  display: ${props => (props.lastPage ? 'none' : 'block')};
  cursor: pointer;
  pointer-events: auto;
  margin-left: 3rem;
  ${media.lessThan('medium')`
    margin-left: 0;
  `}
`

export const PageNumber = styled.div`
  font-size: 1.6rem;
  margin: 0 1rem;
  background-color: ${props => (props.active ? (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary) : '')};
  color: ${props => (props.active ? props.theme.colors.white : '')};
  width: 3.4rem;
  height: 3.4rem;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 2px;
  cursor: ${props => (props.active ? 'normal' : 'pointer')};

  ${media.lessThan('medium')`
    margin: 0;
  `}
`
