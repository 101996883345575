import React from 'react'
import { useTranslations } from 'gatsby-plugin-translate'

import * as S from './styled'
import { useProductDetailContext } from '../../../../contexts'

const Title = () => {
  const t = useTranslations()
  const { productDetail: product } = useProductDetailContext()

  return (
    <S.ProductNameContainer>
      <S.ProductName>{product.name}</S.ProductName>
      {product.synonymies.length !== 0 && product.synonymies !== '' && (
        <S.Synonymies>
          {t`É igual a`} {product.synonymies ? product.synonymies.join(', ') : t`Dados indisponíveis`}
        </S.Synonymies>
      )}
    </S.ProductNameContainer>
  )
}

export default Title
