import styled from 'styled-components'
import media from 'styled-media-query'

export const SizeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  ${media.lessThan('large')`
    width: 90%;
  `}

  ${media.lessThan('medium')`
    width: 95%;
  `};
`

export const BannerContainer = styled.a`
  width: 128rem;
  height: 20rem;
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  border-radius: 0.5rem;
  top: 58vh;

  ${media.greaterThan('huge')`
    top: 58vh;
  `}

  ${media.between('large', 'huge')`
    top: 68vh;
  `}

  ${media.lessThan('large')`
    width: 90%;
  `}

  ${media.lessThan('medium')`
    width: 95%;
  `};
`

export const ImageDesktop = styled.img`
  max-width: 128rem;
  max-height: 20rem;
  border-radius: 0.5rem;

  ${media.lessThan('medium')`
    display:none;
  `}
`
export const ImageMobile = styled.img`
  height: auto;
  width: 100%;
  display: none;
  border-radius: 0.5rem;

  ${media.lessThan('medium')`
    display:block;
  `}
`
