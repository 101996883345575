import styled from 'styled-components'
import media from 'styled-media-query'

export const StateTitle = styled.p`
  margin: 3rem 0 0;
  font-family: 'Roboto Condensed';
  font-size: 2.4rem;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
`

export const StatesContainer = styled.div`
  margin: 3rem 0;
  display: flex;
`

export const StatesInputRadio = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  + label {
    position: relative;
    cursor: pointer;
    padding-left: 3rem;
  }

  + label::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid ${props =>  (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
    vertical-align: bottom;
  }

  &:checked + label::after {
    content: '';
    background-color:${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    position: absolute;
    left: 0.4rem;
    bottom: 0.4rem;
  }
`

export const StatesInputLabel = styled.label`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.beigeGray)};
  cursor: pointer;

  &:first-of-type {
    margin-right: 5.4rem;
  }
`

export const SchedulePrimaryButton = styled.a`
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  background-color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  text-align: center;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  padding: 1.2rem;
  border-radius: 2px;
  opacity: 0.8;
  margin-bottom: 2.5rem;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    opacity: 1;
  `}
`

export const ScheduleSecondaryButton = styled.a`
  display: block;
  font-size: 1.6rem;
  line-height: 2.4rem;
  border: 1px solid ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  text-align: center;
  text-decoration: none;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.secondary : props.theme.colors.primary)};
  padding: 1.2rem;
  border-radius: 2px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    opacity: 1;
  `}
`
