import styled from 'styled-components'

export const OnlineSchedule = styled.a`
  display: block;
  margin-top: 1.6rem;
  text-align: center;
  padding: 1.8rem;
  border-radius: 4px;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  text-decoration: none;
  opacity: 0.95;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: opacity, box-shadow;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    opacity: 1;
  }
`

export const ScheduleInfo = styled.div`
  display: block;
  margin-top: 3rem;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.beigeGray};
  padding: 1rem;
  font-size: 1.6rem;
  line-height: 1.5;
  text-align: center;
  color: ${props => props.theme.colors.white};
`
