import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BudgetFloatingButton from './BudgetFloatingButton'
import BudgetInfo from './BudgetInfo'

import * as S from './styled'

const BudgetContainer = ({
  pageType,
  cartItemsExam,
  cartItemsVaccine,
  openCart,
  notifyCartIsOpen,
  schedulingLink,
  schedulingLinkNav,
  brand,
  nav,
  showButtomSearchExam,
  showButtonSearchVaccine,
  isDetailPage,
}) => {
  const [budgetInfoOpen, setBudgetInfoOpen] = useState(false)

  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  const dataCurrentService = {
    exame: isDetailPage ? "Agendamento de Exames | Exame" : "Agendamento de Exames",
    vacina: isDetailPage ? "Agendamento de Vacinas | Vacina" : "Agendamento de Vacinas",
  }

  const floatingButtonDataAtributtes = {
    dataItemText:"Abrir carrinho",
    dataItemPosition:"Flutuante",
    dataCurrentService: dataCurrentService[pageType],
  }

  const toggleBudgetList = () => {
    setBudgetInfoOpen(!budgetInfoOpen)
  }

  useEffect(() => {
    if (openCart) {
      setBudgetInfoOpen(true)
      notifyCartIsOpen()
    }
  }, [openCart])

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflow = budgetInfoOpen ? 'hidden' : ''
    body.style.position = budgetInfoOpen ? 'relative' : ''

    if (budgetInfoOpen) {
      document.ontouchmove = e => e.preventDefault()
    } else {
      document.ontouchmove = null
    }
  }, [budgetInfoOpen])

  return (
    <S.BudgetContainer>
      {budgetInfoOpen ? (
        <BudgetInfo
          cartItems={cartItems}
          onClick={toggleBudgetList}
          schedulingLink={schedulingLink}
          schedulingLinkNav={schedulingLinkNav}
          brand={brand}
          nav={nav}
          showButtomSearchExam={pageType == 'exame' && showButtomSearchExam}
          showButtonSearchVaccine={pageType == 'vacina' && showButtonSearchVaccine}
        />
      ) : (
        <BudgetFloatingButton 
          cartItems={cartItems} 
          onClick={toggleBudgetList} 
          pageType={pageType} 
          dataAtributtes={floatingButtonDataAtributtes}
        />
      )}
    </S.BudgetContainer>
  )
}

BudgetContainer.propTypes = {
  pageType: PropTypes.string,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  openCart: PropTypes.bool,
  notifyCartIsOpen: PropTypes.func,
  schedulingLink: PropTypes.string,
  showButtomSearchExam: PropTypes.bool,
  showButtonSearchVaccine: PropTypes.bool,
  isDetailPage: PropTypes.bool
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine, openCart }) => ({
  pageType,
  cartItemsExam,
  cartItemsVaccine,
  openCart,
})

const mapDispatchToProps = dispatch => {
  return {
    notifyCartIsOpen: () => {
      dispatch({ type: 'NOTIFY_CART_OPEN' })
    },
  }
}

const ConnectedBudgetContainer = connect(mapStateToProps, mapDispatchToProps)(BudgetContainer)

export default ConnectedBudgetContainer
