import React from 'react'
import PropTypes from 'prop-types'
import { PDFViewer } from '@react-pdf/renderer'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'

import * as S from './styled'

import BudgetDocument from './BudgetDocument'

const BudgetPrint = ({ onClick, pageType, cartItemsExam, cartItemsVaccine, logo }) => {
  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  return (
    <S.BudgetDocumentContainer>
      <ReactTooltip place="top" type="dark" effect="solid" />
      <S.BudgetDocumentClose data-tip="Fechar">
        <S.CloseIcon onClick={onClick} />
      </S.BudgetDocumentClose>
      <PDFViewer width="100%" height="100%">
        <BudgetDocument cartItems={cartItems} logo={logo} />
      </PDFViewer>
    </S.BudgetDocumentContainer>
  )
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine }) => {
  return { pageType, cartItemsExam, cartItemsVaccine }
}

BudgetPrint.propTypes = {
  pageType: PropTypes.string,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  onClick: PropTypes.func,
  logo: PropTypes.string,
}

const ConnectedBudgetPrint = connect(mapStateToProps)(BudgetPrint)

export default ConnectedBudgetPrint
