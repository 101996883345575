import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

import BudgetItemsBadge from '../BudgetItemsBadge'

const BudgetFloatingButton = ({ cartItems, onClick, dataAtributtes }) => {
  return (
    <S.BudgetFloatingButtonContainer
      data-item-text={dataAtributtes.dataItemText}
      data-item-position={dataAtributtes.dataItemPosition}
      data-current-service={dataAtributtes.dataCurrentService}
      onClick={onClick}
    >
      <BudgetItemsBadge cartLength={cartItems.length} />
      <S.BudgetIcon />
    </S.BudgetFloatingButtonContainer>
  )
}

BudgetFloatingButton.propTypes = {
  cartItems: PropTypes.array,
  onClick: PropTypes.func,
  dataAtributtes: PropTypes.object,
}

export default BudgetFloatingButton
