import React, { useState, useEffect } from 'react'
import ScheduleButton from '../ScheduleButton'
import { Translate } from 'gatsby-plugin-translate'
import * as S from './styled'

import { ProductService } from '../../../services'
import { useProductDetailContext } from '../../../contexts'

const Unit = ({productSettings}) => {
  const [units, setUnits] = useState([])
  const [unitState, setUnitState] = useState('SP')

  const brandId = process.env.GATSBY_BRAND_ID

  const { productDetail: product, settings, siteMetadata } = useProductDetailContext()

  useEffect(() => {
    ProductService.getUnitsPerProduct(
      product.id.length > 5 ? product.productIdentifier : product.id
    ).then(setUnits)
  }, [])

  const handleUnitLIstFilteredPerState = e => {
    const unitsFiltered = e.target.value
    setUnitState(unitsFiltered)
  }

  const handleNavLink = (originalLink) => {
    if (typeof window !== 'undefined' && window.location.href.includes('vacinas')) {
      return originalLink.replace("/agd-exames/", "/vacinas/")
    }
    return originalLink
  }

  return (
    <S.Aside>
      <S.UnitsContainer>
        <S.UnitsListTitle>
          <Translate>Selecione a unidade desejada</Translate>:
        </S.UnitsListTitle>
        {Number(brandId) === 19 ? (
          <>
            <S.StatesContainer>
              {['SP', 'RJ'].map((state, index) => (
                <>
                  <S.StatesInputRadio
                    type="radio"
                    id={state}
                    name="stateSelected"
                    value={state}
                    onChange={handleUnitLIstFilteredPerState}
                    defaultChecked={index === 0}
                  />
                  <S.StatesInputLabel htmlFor={state}>{state}</S.StatesInputLabel>
                </>
              ))}
            </S.StatesContainer>

            {unitState === 'RJ' ? (
              <S.ScheduleButton
                href={handleNavLink(settings.scheduling_link_rj)}
                target={handleNavLink(settings.scheduling_link_rj).match(/^https?:\/\//g) ? '_blank' : null}
              >
                <Translate>Agendar</Translate>
              </S.ScheduleButton>
            ) : (
              <S.ScheduleButton
                href={handleNavLink(settings.scheduling_link_sp)}
                target={handleNavLink(settings.scheduling_link_sp).match(/^https?:\/\//g) ? '_blank' : null}
              >
                <Translate>Agendar</Translate>
              </S.ScheduleButton>
            )}
          </>
        ) : (
          <S.UnitsListContainer>
            {units.map((unit, index) => (
              <S.UnitLink key={index} href={unit.slug}>
                <S.Unit>
                  {unit.nome}
                </S.Unit>
              </S.UnitLink>
            ))}
          </S.UnitsListContainer>
        )}
      </S.UnitsContainer>
      { Number(brandId) === 19 ?
        (
          <ScheduleButton schedulingLink={settings.scheduling_link} productSettings={productSettings} />
        ) : (
          <ScheduleButton brand={siteMetadata.brand} schedulingLink={settings.scheduling_link} schedulingLinkNav={settings.scheduling_link_nav} productSettings={productSettings} />
        )
      }
    </S.Aside>
  )
}
export default Unit
