import React from 'react'

import * as S from './styled'

import { useProductContext } from '../../../../contexts'

const TopProducts = () => {
  const {
    productsData: { topSearchs, top_searchs_title: topSearchsTitle },
  } = useProductContext()

  return (
    <S.TopProductsContainer>
      <S.TopProductsTitle>{topSearchsTitle}</S.TopProductsTitle>
      {topSearchs.map((item, index) => (
        <S.TopProductsItemContainer key={index} to={item.split('|')[1].trim()}>
          <S.TopProductsItem>{item.split('|')[0].trim()}</S.TopProductsItem>
        </S.TopProductsItemContainer>
      ))}
    </S.TopProductsContainer>
  )
}

export default TopProducts
