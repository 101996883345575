import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ScheduleButton from '../ScheduleButton'
import SyncLoader from 'react-spinners/SyncLoader'
import { CartPlus } from '@styled-icons/bootstrap/CartPlus'
import { CartDash } from '@styled-icons/bootstrap/CartDash'

import { connect } from 'react-redux'
import slug from 'slug'

import * as S from './styled'

import { useProductDetailContext } from '../../../contexts'

const Price = ({
  pageType,
  cartItemsExam,
  cartItemsVaccine,
  addCartItem,
  removeCartItem,
  openCart,
  productSettings,
}) => {
  const [priceLabel, setPriceLabel] = useState({ text: 'A PARTIR DE' })
  const [prices, setPrices] = useState([])
  const [productPrice, setProductPrice] = useState('')
  const [loading, setLoading] = useState(true)

  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  const { productDetail: product, settings, siteMetadata } = useProductDetailContext()

  const productAlreadyInCart = cartItems.find(item => item.id === product.id) !== undefined

  const dataCurrentService = {
    exame: 'Agendamento de Exames | Exame',
    vacina: 'Agendamento de Vacinas | Vacina',
  }

  useEffect(() => {
    const budgets = product.unityPrice.map(mapBudget)

    if (budgets.length > 0) {
      setPrices(budgets)
      setLoading(false)
    } else {
      setPriceLabel({ text: 'VALORES NÃO DISPONÍVEIS' })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const pricesWithZero = prices.filter(function (obj) {
      return obj.price !== 0
    })

    const lowerPrice = pricesWithZero.sort((a, b) => a.price - b.price)[0]

    if (lowerPrice?.price) {
      setProductPrice(lowerPrice.price)
      setPriceLabel({ text: 'A PARTIR DE' })
    } else {
      setPriceLabel({ text: 'VALORES NÃO DISPONÍVEIS' })
      setProductPrice('Sem valor')
    }
  }, [prices])

  const mapBudget = unit => {
    return {
      name: unit.nomeUnidade,
      id: unit.idUnidade,
      slug: `/unidades/${slug(unit.nomeUnidade)}/${unit.idUnidade}`,
      price: unit.exames[0].preco,
    }
  }

  const addOrRemoveBudgetItem = () => {
    const budgetItem = {
      id: product.id,
      name: product.name,
      slug: `/${product.type}s/${product.slug}`,
      price: productPrice,
      unit: priceLabel.text,
      type: product.type,
    }

    if (productAlreadyInCart) {
      return removeCartItem(budgetItem)
    }

    openCart()

    if (window && window.dataLayer)
      window.dataLayer.push({
        event: 'ga_event',
        eventAction: 'clicou_btn',
        eventCategory: 'institucional_header',
        eventLabel: 'orcamento_exames',
      })

    return addCartItem(budgetItem)
  }

  return (
    <S.Aside>
      <S.PriceContainer>
        <S.Pricelabel>{priceLabel.text}</S.Pricelabel>

        {loading ? (
          <SyncLoader loading={true} color="#aaa" margin="5px" size="10px" />
        ) : (
          <>
            <S.Price>
              {productPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </S.Price>
          </>
        )}

        {productAlreadyInCart ? (
          <S.AddBudgetButton
            onClick={addOrRemoveBudgetItem}
            data-tip="Produto já adicionado ao carrinho."
            enabled
          >
            <CartDash size={24} />
            <span>Remover do carrinho</span>
          </S.AddBudgetButton>
        ) : (
          <S.AddBudgetButton
            data-item-text={`Adicionar ao carrinho | ${product.name}`}
            data-item-position="Card"
            data-current-service={dataCurrentService[product.type]}
            onClick={addOrRemoveBudgetItem}
            enabled={priceLabel.text !== 'VALORES NÃO DISPONÍVEIS'}
          >
            <CartPlus size={24} />
            <span>Adicionar ao carrinho</span>
          </S.AddBudgetButton>
        )}

        <ScheduleButton
          id={product.id}
          brand={siteMetadata.brand}
          nav={siteMetadata.nav}
          schedulingLink={settings.scheduling_link}
          schedulingLinkNav={settings.scheduling_link_nav}
          productSettings={productSettings}
          productName={product.name}
          pageType={pageType}
        />
      </S.PriceContainer>
    </S.Aside>
  )
}

Price.propTypes = {
  pageType: PropTypes.string,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  addCartItem: PropTypes.func,
  removeCartItem: PropTypes.func,
  openCart: PropTypes.func,
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine }) => {
  return { pageType, cartItemsExam, cartItemsVaccine }
}

const mapDispatchToProps = dispatch => {
  return {
    addCartItem: product => dispatch({ type: 'ADD_CART_ITEM', payload: product }),
    removeCartItem: product => dispatch({ type: 'REMOVE_CART_ITEM', payload: product }),
    openCart: () => dispatch({ type: 'OPEN_CART' }),
  }
}

const ConnectedBudget = connect(mapStateToProps, mapDispatchToProps)(Price)

export default ConnectedBudget
