import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as S from './styled'

import BudgetHeader from './BudgetHeader'
import BudgetPrint from '../BudgetPrint'
import BudgetList from './BudgetList'
import BudgetClearAllItems from './BudgetClearAllItems'
import BudgetTotal from './BudgetTotal'
import BudgetExport from './BudgetExport'
import BudgetEmail from './BudgetEmail'
import BudgetActionButtons from './BudgetActionButtons'

const BudgetInfo = ({
  pageType,
  cartItemsExam,
  cartItemsVaccine,
  onClick,
  removeCartItem,
  removeAllCartItems,
  schedulingLink,
  schedulingLinkNav,
  brand,
  nav,
  showButtomSearchExam,
  showButtonSearchVaccine,
}) => {
  const [togglePrint, setTogglePrint] = useState(false)
  const [toggleEmail, setToggleEmail] = useState(false)

  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  const logo = process.env.GATSBY_PRINT_LOGO

  const removeBudgetItem = item => () => {
    const budgetItem = {
      id: item.id,
    }
    removeCartItem(budgetItem)
  }

  const sumItems = items => {
    return items
      .reduce((total, item) => total + item.price, 0.0)
      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }

  return (
    <>
      <S.BudgetBackground onClick={onClick} />
      {togglePrint && (
        <BudgetPrint
          onClick={() => setTogglePrint(false)}
          logo={`${logo}?${new Date().getTime()}`}
        />
      )}
      <S.BudgetInfoContainer
        data-item-text="Carrinho"
        data-item-position="Carrinho"
        data-current-service={pageType === 'exame' ? 'Agendamento de Exames' : 'Agendamento de Vacinas'}
      >
        <BudgetHeader budgetLength={cartItems.length} onClick={onClick} />
        {toggleEmail ? (
          <BudgetEmail
            onCancel={() => setToggleEmail(false)}
            logo={`${logo}?${new Date().getTime()}`}
          />
        ) : (
          <>
            <BudgetList
              budgetItems={cartItems}
              removeBudgetItem={removeBudgetItem}
              onClick={onClick}
            />
            <BudgetClearAllItems budgetItems={cartItems} clearAllItems={removeAllCartItems} />
            <S.BudgetFooter>
              <BudgetTotal total={sumItems(cartItems)} />
              <BudgetExport
                enabled={cartItems.length > 0}
                togglePrint={() => setTogglePrint(true)}
                toggleEmail={() => setToggleEmail(true)}
                logo={`${logo}?${new Date().getTime()}`}
              />
              <BudgetActionButtons
                pageType={pageType}
                budgetItems={cartItems}
                onClick={onClick}
                schedulingLink={schedulingLink}
                schedulingLinkNav={schedulingLinkNav}
                brand={brand}
                nav={nav}
                showButtomSearchExam={showButtomSearchExam}
                showButtonSearchVaccine={showButtonSearchVaccine}
              />
            </S.BudgetFooter>
          </>
        )}
      </S.BudgetInfoContainer>
    </>
  )
}

BudgetInfo.propTypes = {
  pageType: PropTypes.string,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  onClick: PropTypes.func,
  removeCartItem: PropTypes.func,
  removeAllCartItems: PropTypes.func,
  schedulingLink: PropTypes.string,
  showButtomSearchExam: PropTypes.bool,
  showButtonSearchVaccine: PropTypes.bool,
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine }) => {
  return { pageType, cartItemsExam, cartItemsVaccine }
}

const mapDispatchToProps = dispatch => {
  return {
    removeCartItem: product => dispatch({ type: 'REMOVE_CART_ITEM', payload: product }),
    removeAllCartItems: product => dispatch({ type: 'REMOVE_ALL_CART_ITEMS', payload: product }),
  }
}

const ConnectedBudgetInfo = connect(mapStateToProps, mapDispatchToProps)(BudgetInfo)

export default ConnectedBudgetInfo
