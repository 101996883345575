import React, { memo } from 'react'

import { BannerContainer, BannerImage } from './styled'

const BannerBox = ({ link, children }) => {
  if (!link) {
    return children
  }

  return (
    <a href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

const ProductBanner = ({ active, link, image }) => {
  if (!active) {
    return null
  }

  return (
    <BannerContainer data-testid="product-banner">
      <BannerBox link={link}>
        <BannerImage src={image.url} alt={image.alt} />
      </BannerBox>
    </BannerContainer>
  )
}

export default memo(ProductBanner)
