import React, { useContext } from 'react'
import PropTypes from 'prop-types'

const ProductDetailContext = React.createContext({})

const ProductDetailProvider = ({ productState, children }) => {
  return (
    <ProductDetailContext.Provider value={productState}>{children}</ProductDetailContext.Provider>
  )
}

ProductDetailProvider.propTypes = {
  productState: PropTypes.object,
  children: PropTypes.element,
}

const useProductDetailContext = () => useContext(ProductDetailContext)

export { ProductDetailProvider, useProductDetailContext }
