import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { PDFDownloadLink } from '@react-pdf/renderer'

import { mediaQuery } from '@cms/utils'

import BudgetDocument from '../BudgetPrint/BudgetDocument'

import * as S from './styled'

const BudgetExport = ({
  pageType,
  cartItemsExam,
  cartItemsVaccine,
  enabled,
  togglePrint,
  toggleEmail,
  logo,
}) => {
  const cartItems = pageType === 'exame' ? cartItemsExam : cartItemsVaccine

  const PdfBlobContainer = (
    <>
      <BudgetDocument cartItems={cartItems} logo={logo} />
    </>
  )

  return (
    <S.BudgetExportContainer>
      {mediaQuery.isMedium() && (
        <S.DownloadButtonContainer enabled={enabled}>
          <PDFDownloadLink document={PdfBlobContainer} fileName="orçamento.pdf">
            {({ blob }) => (
              <S.DownloadButton
                data-item-text="Download do orçamento"
                data-item-position="Carrinho"
                data-current-service={pageType === 'exame' ? "Agendamento de Exames" : "Agendamento de Vacinas"}
              >
                <S.DownloadIcon />
                Download do orçamento
              </S.DownloadButton>
            )}
          </PDFDownloadLink>
        </S.DownloadButtonContainer>
      )}
      {!mediaQuery.isMedium() && (
        <S.PrintButton onClick={togglePrint} enabled={enabled}>
          <S.PrinterIcon />
          Imprimir orçamento
        </S.PrintButton>
      )}
      <S.EmailButton
        data-item-text="Enviar por e-mail"
        data-item-position="Carrinho"
        data-current-service={pageType === 'exame' ? "Agendamento de Exames" : "Agendamento de Vacinas"}
        enabled={enabled}
        onClick={toggleEmail}>
        <S.MailIcon />
        Enviar por e-mail
      </S.EmailButton>
    </S.BudgetExportContainer>
  )
}

BudgetExport.propTypes = {
  pageType: PropTypes.string,
  cartItemsExam: PropTypes.array,
  cartItemsVaccine: PropTypes.array,
  enabled: PropTypes.bool,
  togglePrint: PropTypes.func,
  toggleEmail: PropTypes.func,
  logo: PropTypes.string,
}

const mapStateToProps = ({ pageType, cartItemsExam, cartItemsVaccine }) => {
  return { pageType, cartItemsExam, cartItemsVaccine }
}

const ConnectedBudgetExport = connect(mapStateToProps)(BudgetExport)

export default ConnectedBudgetExport
