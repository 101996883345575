import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../shared/container'

export const MainContainer = styled(Container)`
  margin-bottom: 10rem;
  margin-top: 4.8rem;

  ${media.lessThan('medium')`
    margin-bottom: 3rem;
    margin-top: 3rem;
  `}
`

export const ProductsContainer = styled.div`
  margin: 4rem 0 0;
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 0 2.4rem;

  grid-template-areas:
		"asideContainer search"
    "asideContainer result"
    "asideContainer pagination"
	;

  ${media.greaterThan('1820px')`
    grid-template-columns: 2fr 8fr;
  `}

  ${media.greaterThan('huge')`
    grid-template-columns: 3fr 9fr;
  `}

  ${media.lessThan('medium')`
    grid-template-columns: 1fr;
    margin: 2.4rem 0 0 0;
    gap: 0;
    grid-template-areas:
    "vaccination"
		"search"
    "alert"
    "result"
    "pagination"
    "banner"
    "topProducts"
	;
  `}
`

export const AsideContainer = styled.div`
  /* height: max-content; */
  /* border: 1px solid ${props => props.theme.colors.primary}; */
  /* padding: 3.2rem; */
  grid-area: asideContainer;
`

export const TopProductsTitle = styled.h3`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  color: ${props => (props.theme.colors.alta ? props.theme.colors.tertiary : props.theme.colors.primary)};
  margin: 0;
`

export const SearchResultsContainer = styled.div``

export const ResultsRow = styled.div`
  display: flex;
  color: ${props => props.theme.colors.beigeGray};
  font-size: 1.4rem;
  justify-content: center;
  margin: 2.4rem 0 0 0;

  ${media.lessThan('medium')`
    margin-bottom: 3.2rem;
  `}
`
export const PaginatorContainer = styled.div`
  grid-area: pagination;
`
export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
`
