import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const BudgetItemsBadge = ({ cartLength }) => {
  return <S.BudgetItemsBadge>{cartLength}</S.BudgetItemsBadge>
}

BudgetItemsBadge.propTypes = {
  cartLength: PropTypes.number,
}

export default BudgetItemsBadge
