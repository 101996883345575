import React from 'react'
import { Translate, useTranslations } from 'gatsby-plugin-translate'
import CoberturaAns from '../../../assets/images/cobertura_ans.svg'
import NaoCoberturaAns from '../../../assets/images/nao_cobertura_ans.svg'
import getIcon from '../Icons'

import * as S from './styled'
import ReactTooltip from 'react-tooltip'
import { useProductDetailContext } from '../../../../contexts'

const AnsCover = () => {
  const { productDetail: product } = useProductDetailContext()
  const isAnsCover = product.ansCover
  const t = useTranslations()

  return (
    <S.AnsCoverContainer>
      {isAnsCover ? (
        <>
          <S.AnsIcon size={32}>
            <use xlinkHref={`#${getIcon(CoberturaAns.id)}`} />
          </S.AnsIcon>

          <S.AnsCoverTitleWrapper>
            <S.AnsCoverTitle>
              <Translate>Este exame possui cobertura da ANS</Translate>
            </S.AnsCoverTitle>
            <S.AnsTooltipContainer>
              <S.AnsCoverInfoIcon
                data-tip={t`Agência Nacional de Saúde Suplementar`}
                data-background-color="gray"
                placement="bottom"
              />
              <ReactTooltip place="right" />
            </S.AnsTooltipContainer>
          </S.AnsCoverTitleWrapper>

          <S.AnsCoverInfo>
            <Translate>Verifique se o procedimento é coberto pelo seu plano de saúde.</Translate>
          </S.AnsCoverInfo>
        </>
      ) : (
        <>
          <S.AnsIcon size={32}>
            <use xlinkHref={`#${getIcon(NaoCoberturaAns.id)}`} />
          </S.AnsIcon>
          <S.AnsCoverTitle>
            <Translate>Este exame não possui cobertura da ANS</Translate>{' '}
            <S.AnsTooltipContainer>
              <S.AnsCoverInfoIcon
                data-tip={t`Agência Nacional de Saúde Suplementar`}
                data-background-color="gray"
                placement="bottom"
              />
              <ReactTooltip place="right" />
            </S.AnsTooltipContainer>
          </S.AnsCoverTitle>
        </>
      )}
    </S.AnsCoverContainer>
  )
}

export default AnsCover
